<template>
<ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(validate)">
    <div class="flex lg:space-x-4">
      <div class="w-full lg:w-1/2">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseInput type="text" label="Name" name="name" v-model="payload.name" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseSelect label="School" name="school_id" optionKey="id" optionValue="name" :options="schools" v-model="payload.school_id" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>
      <div class="w-full lg:w-1/2">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required|email" class="mb-2">
          <BaseInput type="email" label="Email" name="email" v-model="payload.email" autocomplete="email" placeholder="Email" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseSelect label="Role" name="role" :options="roles" v-model="payload.role" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>
    </div>

    <password-update-from v-if="!payload.id" />

    <BaseBtn type="submit"  :text="payload.id ? 'Update' : 'Add'" class="mt-2" />
    <FlashMessage :message="message" :error="error" />
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Please enter your email",
});

extend("confirmed", {
  ...confirmed,
  message: "New password must be the same",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import BaseSelect from "@/components/BaseSelect";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import SchoolService from "@/services/SchoolService";
import FlashMessage from "@/components/FlashMessage";
import PasswordUpdateFrom from "@/components/PasswordUpdateFrom.vue";

export default {
  name: "AuthUserForm",
  components: {
    BaseBtn,
    BaseInput,
    BaseSelect,
    FlashMessage,
    PasswordUpdateFrom,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      payload: {
        name: "",
        email: "",
        role: "",
        school_id: null
      },
      roles: [
        { value: "Teacher", key: "teacher" },
        { value: "Teaching Assistant", key: "teaching-assistant" },
        { value: "Super Admin", key: "super-admin" },
      ],
      password: '',
      schools: [],
      error: null,
      message: null,
    };
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("school", ["getSchools"]),
  },
  methods: {
    validate() {
      this.error = null;
      this.message = null;

      if (this.payload.id) {
        UserService.update(this.payload)
          .then(() => this.$store.dispatch("auth/getAuthUser"))
          .then(() => (this.message = "User updated."))
          .then(() => (this.$parent.close()))
          .then(() => (this.$eventBus.$emit("users:updated", true)))
          
          .catch((error) => (this.error = getError(error)));
      } else {
        UserService.add(this.payload)
          .then(() => {
            this.message = "User added.";
            this.$parent.close();
            this.$eventBus.$emit("users:added", true);
          })
          .catch((error) => (this.error = getError(error)));
      }
    },
  },
  async created() {
    await SchoolService.getSchools().then((response) => {
      this.schools = response.data.data;
    });

    this.$eventBus.$on("users:password", (val) => {
      this.payload.password = val;
    });
  },
  async mounted() {
    if (this.attributes.payload) {
      this.payload = this.attributes.payload;
      this.payload.role = this.attributes.payload.roles[0];
      this.payload.school_id = Number(this.attributes.payload.school.id);
    }

    console.log('payload', this.payload);
  },
};
</script>
