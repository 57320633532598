var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validate)}}},[_c('div',{staticClass:"flex lg:space-x-4"},[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"text","label":"Name","name":"name"},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseSelect',{attrs:{"label":"School","name":"school_id","optionKey":"id","optionValue":"name","options":_vm.schools},model:{value:(_vm.payload.school_id),callback:function ($$v) {_vm.$set(_vm.payload, "school_id", $$v)},expression:"payload.school_id"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"w-full lg:w-1/2"},[_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"email","label":"Email","name":"email","autocomplete":"email","placeholder":"Email"},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseSelect',{attrs:{"label":"Role","name":"role","options":_vm.roles},model:{value:(_vm.payload.role),callback:function ($$v) {_vm.$set(_vm.payload, "role", $$v)},expression:"payload.role"}}),(errors[0])?_c('p',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),(!_vm.payload.id)?_c('password-update-from'):_vm._e(),_c('BaseBtn',{staticClass:"mt-2",attrs:{"type":"submit","text":_vm.payload.id ? 'Update' : 'Add'}}),_c('FlashMessage',{attrs:{"message":_vm.message,"error":_vm.error}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }